
import IconAction from '@/components/IconAction/IconAction.vue'
import ViewModel from '@/models/ViewModel'
import { Component, Ref, Vue } from 'vue-property-decorator'
import DataTable from '@/components/DataTable/index.vue'
import PrePlan from '@/models/PrePlan'
import { getModule } from 'vuex-module-decorators'
import SystemtModule from '@/store/SystemModule'
import Widget from '@/components/Widget/Widget.vue'
import SelectOption from '@/models/interface/SelectOption'
import SelectPicker from '@/components/SelectPicker/SelectPicker.vue'
import pre_plan_home_fields from './pre-plan-home-fields'

@Component({
  components: {
    IconAction,
    DataTable,
    Widget,
    SelectPicker,
  },
})
export default class PrePlanHome extends ViewModel {
  @Ref() readonly dataTable!: HTMLFormElement

  public fields: any = pre_plan_home_fields

  public selected: any = []

  public query: string[] = []

  public records: number = 0

  public fieldFilters: any = {}

  public ready: boolean = true

  public busy: boolean = false

  private target_id: string = ''

  public export_columns: string[] = ['net']

  public export_column_options: SelectOption[] = [
    new SelectOption('Net Cost', 'net'),
    new SelectOption('Gross Cost', 'gross'),
  ]

  public createNewPlan() {
    this.$router.push({ name: 'preplan' })
  }

  public mounted() {
    this.loadFilters()
  }

  public get table_fields() {
    return this.fields.filter(f => f.show)
  }

  public async preplans(context: any) {
    this.loading = true

    const field_filters = Object.keys(this.fieldFilters)
      .filter((key: string) => this.fieldFilters[key] !== '')
      .map((key: string) => `${key}:${this.fieldFilters[key]?.toLowerCase()}`)

    this.syncFilters()

    return PrePlan.paginate({
      page_size: context.perPage,
      page: context.currentPage,
      order_by: context.sortBy,
      order: context.sortDesc ? 'desc' : 'asc',
      query: [...context.filter, ...field_filters],
    }).then(result => {
      this.records = result.records
      this.loading = false
      return result.data
    })
  }

  public exportFile(data: any) {
    this.target_id = data.id
    this.$bvModal.show('export-modal')
  }

  public confirmExportFile() {
    PrePlan.download({
      type: 'csv',
      id: this.target_id,
      columns: this.export_columns,
    })
  }

  // Store session filters in VUEX
  public syncFilters() {
    const system = getModule(SystemtModule)
    system.updateState({
      name: 'filters',
      type: 'pre-plans',
      data: { query: this.query, fieldFilters: this.fieldFilters },
    })
  }

  // Load filters from VUEX if present
  public loadFilters() {
    const system = getModule(SystemtModule)
    system.getFilter('pre-plans').then((filter: any) => {
      if (filter) {
        this.query = filter.query
        this.fieldFilters = filter.fieldFilters
      }
      this.ready = true
    })
  }

  // Clear VUEX filters state
  public clearFilters() {
    const system = getModule(SystemtModule)
    system.updateState({
      name: 'filters',
      type: 'pre-plans',
      data: null,
    })
  }

  // Refresh dataTable
  public refresh() {
    this.dataTable.refresh()
  }

  public resetFilters() {
    this.fieldFilters = {}
    this.query = []
    this.clearFilters()
    this.refresh()
  }
}
